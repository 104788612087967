import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { navigate } from "gatsby";
import * as Ant from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";

import * as Survey from "../../Contexts/SurveyContext";
import { Color } from "../Widget";

const ImportTemplateModal = props => {
  const { Subject: orgSubject = "", TemplateId: id = null } =
    props.selectedTemplate || {};
  const { visible, setShowImportTemplateModal } = props;

  const [surveyList, setSurveyList] = useState([]);
  const [options, setOptions] = useState([]);
  const [survey, setSurvey] = useState(null);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [findFromSurveyData, setFindFromSurveyData] = useState(undefined);

  const fromSurveyId = props.fromSurveyId;

  async function fetchSurveyList() {
    let list = await Survey.Actions.fetchMyAllSurveys();
    const opt = list.map(renderItem);
    const optFiltered = opt.filter(o => !o.audit); // 過濾已凍結的問卷
    setSurveyList(list);
    setOptions(optFiltered);

    if (fromSurveyId !== undefined && fromSurveyId !== null) {
      var find = list.find(l => l.id === fromSurveyId);
      setFindFromSurveyData(find);
    }
  }

  // render autocomplete 內容
  const renderItem = item => ({
    audit: item.audit,
    author: item.author,
    value: item.title,
    label: (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            width: "50%",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
        >
          {item.title}
        </div>
        <div
          style={{
            width: "50%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <a>{item.author}</a>
          <div>{formatDate(new Date(item.modified))}</div>
        </div>
      </div>
    ),
  });

  // 時間補至2位
  const padTo2Digits = num => {
    return num.toString().padStart(2, "0");
  };

  // 時間 format
  const formatDate = date => {
    return (
      [
        date.getFullYear(),
        padTo2Digits(date.getMonth() + 1),
        padTo2Digits(date.getDate()),
      ].join("-") +
      " " +
      [
        padTo2Digits(date.getHours()),
        padTo2Digits(date.getMinutes()),
        // padTo2Digits(date.getSeconds()),
      ].join(":")
    );
  };

  const onSelect = value => {
    findSurvey(value);
  };

  const onChange = value => {
    findSurvey(value);
  };

  const findSurvey = text => {
    const _surveyList = [...surveyList];
    // 行為上使用者需要名稱完全相同(點選AutoComplete)，才視為有答案
    // const findSurvey = _surveyList.find(survey => survey.title.indexOf(text) != -1 );
    const survey = _surveyList.find(survey =>
      [survey.title, survey.author].includes(text)
    );
    setSurvey(survey);
  };

  async function handleOk(setId = null) {
    setConfirmLoading(true);
    if (typeof setId === "object") {
      setId = null;
    }
    await Survey.Actions.copyFromTemplate(
      id,
      setId != null ? setId : survey.id
    );
    setConfirmLoading(false);
    setShowImportTemplateModal(false);

    navigate(`/survey?id=${setId != null ? setId : survey.id}&import=1`);
  }

  async function handleCancel() {
    setShowImportTemplateModal(false);
  }

  useEffect(() => {
    fetchSurveyList();
  }, []);

  useEffect(() => {
    if (visible && fromSurveyId != undefined && fromSurveyId !== null) {
      openConfirm();
    }
  }, [visible]);

  function openConfirm() {
    Ant.Modal.confirm({
      title: "確認要匯入此範本嗎？",
      content: (
        <div>
          預計把範本 <span style={{ color: "#534ab3" }}>{orgSubject}</span>{" "}
          匯入至{" "}
          <span style={{ color: "#534ab3" }}>
            {findFromSurveyData !== undefined ? findFromSurveyData.title : ""}
          </span>
          中
        </div>
      ),
      okText: "確定",
      okType: "primary",
      onOk: () => {
        handleOk(findFromSurveyData.id);
      },
      cancelButtonProps: { type: "ghost" },
      cancelText: "取消",
      onCancel: async () => {},
      icon: <ExclamationCircleFilled color={Color.LightGold} size={20} />,
    });
  }

  function openDirectConfirm() {
    Ant.Modal.confirm({
      title: "確認要匯入此範本嗎？",
      content: (
        <div>
          預計把範本 <span style={{ color: "#534ab3" }}>{orgSubject}</span>{" "}
          匯入至{" "}
          <span style={{ color: "#534ab3" }}>
            {survey !== undefined ? survey.title : ""}
          </span>
          中
        </div>
      ),
      okText: "確定",
      okType: "primary",
      onOk: () => {
        handleOk(survey.id);
      },
      cancelButtonProps: { type: "ghost" },
      cancelText: "取消",
      onCancel: async () => {},
      icon: <ExclamationCircleFilled color={Color.LightGold} size={20} />,
    });
  }

  return fromSurveyId === undefined || fromSurveyId === null ? (
    <Ant.Modal
      className="custom-modal"
      title="把範本匯入指定的問卷"
      visible={visible}
      bodyStyle={{ padding: "32px 24px 24px" }}
      width={600}
      okText="確定"
      cancelText="取消"
      centered
      confirmLoading={confirmLoading}
      onCancel={handleCancel}
      onOk={openDirectConfirm}
    >
      <Wrapper>
        <div className="col space">
          <div className="head">將範本</div>
          <a>{orgSubject}</a>
        </div>
        <div className="col">
          <div
            className="head"
            style={{ display: "flex", alignItems: "center" }}
          >
            匯入至
          </div>
          <Ant.AutoComplete
            options={options}
            style={{ width: "100%" }}
            filterOption={(inputValue, option) => {
              return (
                (option.value && option.value.indexOf(inputValue) !== -1) ||
                (option.author && option.author.indexOf(inputValue) !== -1)
              );
            }}
            onSelect={onSelect}
            onChange={onChange}
          >
            <Ant.Input.Search
              placeholder="請輸入問卷名稱"
              style={{ height: "40px" }}
            />
          </Ant.AutoComplete>
        </div>
      </Wrapper>
    </Ant.Modal>
  ) : (
    <div></div>
  );

  return (
    <Ant.Modal
      className="custom-modal"
      title="把範本匯入指定的問卷"
      visible={visible}
      bodyStyle={{ padding: "32px 24px 24px" }}
      width={600}
      okText="確定"
      cancelText="取消"
      centered
      confirmLoading={confirmLoading}
      onCancel={handleCancel}
      onOk={handleOk}
    >
      <Wrapper>
        <div className="col space">
          <div className="head">將範本</div>
          <a>{orgSubject}</a>
        </div>
        <div className="col">
          <div
            className="head"
            style={{ display: "flex", alignItems: "center" }}
          >
            匯入至
          </div>
          <Ant.AutoComplete
            options={options}
            style={{ width: "100%" }}
            filterOption={(inputValue, option) => {
              return (
                (option.value && option.value.indexOf(inputValue) !== -1) ||
                (option.author && option.author.indexOf(inputValue) !== -1)
              );
            }}
            onSelect={onSelect}
            onChange={onChange}
          >
            <Ant.Input.Search
              placeholder="請輸入問卷名稱"
              style={{ height: "40px" }}
            />
          </Ant.AutoComplete>
        </div>
      </Wrapper>
    </Ant.Modal>
  );
};

const Wrapper = styled.div`
  .col {
    display: flex;
    flex-direction: row;

    .head {
      flex: 0 0;
      flex-basis: 48px;
      margin-right: 16px;
    }
  }

  .space {
    margin-bottom: 16px;
  }

  .ant-input-suffix {
    display: flex;
    align-items: center;
  }
`;

export default ImportTemplateModal;
